// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-list-blog-list-jsx": () => import("./../src/templates/BlogList/BlogList.jsx" /* webpackChunkName: "component---src-templates-blog-list-blog-list-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-index-js": () => import("./../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-newsletter-js": () => import("./../src/pages/en/newsletter.js" /* webpackChunkName: "component---src-pages-en-newsletter-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */)
}

